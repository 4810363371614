<template>
    <v-card>
        <v-card-title style="font-size: 25px;">CLIENTE: {{ datos.full_name }} </v-card-title>
        <v-card-text>
            <v-col cols="6">
                <v-col class="d-flex flex-row">
                    <v-row style="font-size: 15px;">Correo: {{ correoCliente }}</v-row>
                    <v-card-actions>
                        <v-btn v-if="datos.emailClient" :disabled="datos.isEmailEntregado" color="primary" @click="sendEmail()">Enviar a Correo</v-btn>
                    </v-card-actions>
                </v-col>
                <v-col class="d-flex flex-row">
                    <v-row v-if="datos.isEmailEntregado" style="font-size: 17px;">EL CORREO YA HA SIDO ENVIADO</v-row>
                </v-col>
                <v-col class="d-flex flex-row">
                    <v-row style="font-size: 15px;"></v-row>
                </v-col>
                <v-col class="d-flex flex-row">
                    <v-row style="font-size: 15px;">Telefono: {{ telefonoCliente }}</v-row>
                    <v-card-actions>
                        <v-btn v-if="datos.phoneClient" color="primary" @click="confirmaReserva()">Enviar a whatsapp</v-btn>
                    </v-card-actions>
                </v-col>
            </v-col>
            <v-spacer></v-spacer>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { sleep } from '@/utils/genericUtils'
export default {
    computed: {
        correoCliente() {
            return this.datos.emailClient || 'No existe un correo registrado'
        },
        telefonoCliente() {
            return this.datos.phoneClient || 'No existe un número registrado'
        },
        ...mapState('ventas', ['datos']),
        ...mapState('sedes', ['sedes'])
    },
    methods: {
        async sendEmail() {
            const sedes = { ...this.sedes }
            let hourService = ''
            for (const item of this.datos.privados) {
                const [hours, minutes] = item.time.split(':').map(Number)
                const date = new Date()
                date.setHours(hours)
                date.setMinutes(minutes)
                const formattedDate = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                hourService = hourService.concat(`• ${formattedDate}<br>`)
            }
            await this.$store.dispatch('clientes/enviarEmail', { ...this.datos, sedes, hourService })
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'Se envio correctamente el correo',
                color: 'green'
            })
            await sleep(2000)

            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },
        confirmaReserva() {
            const { id, celularContacto, telefono, phoneClient, full_name, idSede, check_in } = this.datos
            const findSede = this.sedes.find((x) => x.id == idSede)
            let hourService = ''
            for (const item of this.datos.privados) {
                const [hours, minutes] = item.time.split(':').map(Number)
                const date = new Date()
                date.setHours(hours)
                date.setMinutes(minutes)
                const formattedDate = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                hourService = hourService.concat(`%0A• ${formattedDate}`)
            }
            this.msgWhatsapp = `KUNTE SPA ${findSede.province}
              %0AHola ${full_name || ''}
              %0AGracias por elegir Kunte spa ${findSede.province || ''} . Tenemos muchas ganas de recibirte.
              %0AAquí están los detalles de tu reserva:
              %0ANúmero de ticket : ${id || ''}
              %0AFecha de Reserva : ${check_in || ''}
              %0AHora de Reserva :
              ${hourService || ''}
              %0ASi necesitas hacer cambios o requieres asistencia por favor llama ${findSede.telefono || ''} o mándanos un correo a ${findSede.correo || ''}.
              %0A¡Estaremos a la espera de tu llegada!`
            console.log('this.msgWhatsapp', this.msgWhatsapp)
            window.open(`https://api.whatsapp.com/send?phone=51${phoneClient || celularContacto || telefono}&text=${this.msgWhatsapp}`, '_blank')
        }
    }
}
</script>
